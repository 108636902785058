<template>
  <div id="app" :style="personStyle" @scroll="listenScroll">
    <router-view/>

    <!-- <el-backtop target="#app" :bottom="100"></el-backtop> -->
    <!-- <div class="backTop">top</div> -->

    <!-- <div class="contact_way" v-show="showContact">
      <div class="txt">联系我们</div>
      <div class="way">
        <img src="./assets/imgs/place53.png"/>
        <span>微信二维码</span>
      </div>
      <div class="way">
        <img src="./assets/imgs/place54.png"/>
        <span>联系咨询</span>
      </div>
    </div> -->

    <div class="contact_way2" v-show="showContact">
      <el-popover
        placement="left"
        trigger="hover" v-if="showRight">
        <div class="wechat_popover">
          <div>微信咨询</div>
          <img :src="contact_wxQrcode?contact_wxQrcode:require('./assets/imgs/place55.png')"/>
          <div>手动扫码加我微信</div>
        </div>

        <div class="contactItem wx" slot="reference" v-if="showRight">
          <img src="./assets/imgs/effect24.png"/>
          <!-- <div class="txt">联系我们</div> -->
        </div>
      </el-popover>
      <el-popover
        placement="left"
        trigger="hover" v-if="showRight">
        <div class="phone_popover">
          <img src="./assets/imgs/place56.png"/>
          <div class="right">
            <div class="phone">{{contact_telephone||'400-123-8888'}}</div>
            <div class="time">(9:00-22:00)</div>
          </div>
        </div>
        <div class="contactItem pho" slot="reference" v-if="showRight">
          <img src="./assets/imgs/effect25.png"/>
          <!-- <div class="txt">电话咨询</div> -->
        </div>
      </el-popover>

      <div class="contactItem top" slot="reference" :style="{'cursor':'pointer','opacity':showTopBtn?1:0}" @click="clickToTop">
          <img src="./assets/imgs/effect26.png"/>
          <!-- <div class="txt">返回顶部</div> -->
        </div>
    </div>
  </div>
</template>

<script>
import {getWebsiteSetting} from "@/axios/api"
export default {
  data(){
    return{
      personStyle:{
        // 'padding-top':'60px'
      },
      websiteLogo:"",
      showContact:true,
      showTopBtn:false,
      showRight:false,
      contact_telephone:"",
      contact_wxQrcode:"",
    }
  },
  methods: {
    pxToRem(v){
      return Math.round(v/16*100000)/100000
    },
    modTop() {
      console.log(this.$route)
      if(['/register','/login','/forgetPwd','/furnitureDetail'].includes(this.$route.path)){
        this.personStyle={
          'padding-top':'0px'
        }
      }else if(this.$route.path.indexOf('/personal')!=-1){
        this.personStyle={
          'padding-top':'60px'
        }
      }else{
        this.personStyle={}
      }
    },

    // 监听滚动事件
    listenScroll(){
      // console.log(123456)
    },
    // 查询网站配置
		gainWebsiteSetting(){
			getWebsiteSetting({
				host:location.host,
				// host:"www.i-dian.com",
			}).then(res=>{
				console.log("网站配置a",res);
				if(res.data.websiteLogo){
					this.websiteLogo=res.data.websiteLogo;
          // console.log(1111,this.$bus)
          sessionStorage.websiteLogo=this.websiteLogo;
          localStorage.AGY_websiteLogo=this.websiteLogo;
          this.$bus.$emit('gettingConfig')
        }
        if(res.data.businessId){
          localStorage.AOGEYA_businessId=res.data.businessId
        }
        if(res.data.websiteName){
          sessionStorage.websiteName=res.data.websiteName;
          this.$bus.$emit('gettingConfig');
          this.$nextTick(()=>{
            document.title=res.data.websiteName
          })
        }
        if(res.data.telephone){
          this.contact_telephone=res.data.telephone;
        }
        if(res.data.wxQrcode){
          this.contact_wxQrcode=res.data.wxQrcode;
        }
				// res.data.bannerPlaceList.forEach(item=>{
				// 	if(item.bannerPlace==1){
				// 		this.homeBannerList=item.bannerSettingList
				// 	}
				// })
			})
		},
    clickToTop(){
      var scrollTop=document.querySelector("#app").scrollTop
      var scrollTop2=scrollTop/20;

      var timer=setInterval(()=>{
        scrollTop=scrollTop-scrollTop2
        document.querySelector("#app").scrollTop=scrollTop;
        if(scrollTop<=0){
          clearInterval(timer)
        }
      },20)
    },
  },
  created() {
    // this.modTop()
    this.gainWebsiteSetting();
    this.$bus.$on('limitShowRight',(val)=>{
      if(val=="Y"){
        this.showRight=true;
      }else{
        this.showRight=false;
      }
    })
  },
  mounted() {
    // 禁用右键菜单
    // document.addEventListener('contextmenu', function(e) {
    //     e.preventDefault();
    //     return false;
    // });
    // 禁用F12开发者工具
    // document.addEventListener('keydown', function(e) {
    //     if(e.keyCode === 123) { // 123 是 F12 的 keyCode
    //         e.preventDefault();
    //         return false;
    //     }
    // });

    var app=document.querySelector("#app")
    app.addEventListener("scroll",()=>{
      // console.log(app.scrollTop)
      if(app.scrollTop>window.innerHeight){
        this.showTopBtn=true
      }else{
        this.showTopBtn=false
      }
    })
  },
  watch:{
    '$route'(n,o){
      // this.modTop();
      document.querySelector("#app").scrollTop=0;
      if(['/login','/register','/forgetPwd'].includes(n.path)){
        this.showContact=false;
      }else{
        this.showContact=true;
      }
    }
  }
}
</script>

<style lang="less">
*{
  margin: 0;
  padding: 0;
}
html,body{
  height: 100%;
  // font-family: "思源黑体";
  // font-family: "oppo sans";
  font-family: "微软雅黑";
  &::-webkit-scrollbar{
    width: 0;
  }
}
#app{
  height: 100%;
  // padding-top: 285px;
  // padding-top: 256px;
  overflow: auto;
  &::-webkit-scrollbar{
    width: 0;
  }
  &>.backTop{
    position: fixed;
    right: 100px;
    bottom: 100px;
    width: 40px;
    height: 40px;
    background: #fff;
    box-shadow: 0 0 6px rgba(0, 0, 0, .12);
    border-radius: 50%;
    cursor: pointer;
  }

  .el-backtop{
    width: 60px;
    height: 60px;
    font-size: 40px;
  }

  .contact_way{
    position: fixed;
    top: 50%;
    left: calc(50% + 800px);
    transform: translateY(-50%);
    z-index: 1000;
    // width: 153px;
    // height: 426px;
    width: 127px;
    height: 280px;
    border-radius: 80px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 8px  rgba(0, 0, 0, 0.15);
    // padding: 55px 0;
    padding: 30px 0;
    .txt{
      text-align: center;
      // font-size: 18px;
      // font-weight: 700;
      // letter-spacing: 0px;
      // line-height: 26.06px;
      // color: rgba(0, 0, 0, 1);
      font-size: 14px;
      font-weight: 400;
      line-height: 20.27px;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 10px;
    }
    .way{
      display: flex;
      flex-direction: column;
      align-items: center;
      // margin-bottom: 56px;
      margin-bottom: 20px;
      cursor: pointer;
      &>img{
        // width: 76px;
        // height: 76px;
        width: 55px;
        height: 55px;
      }
      &>span{
        margin-top: 10px;
        // font-size: 18px;
        // font-weight: 400;
        // letter-spacing: 0px;
        // line-height: 26.06px;
        // color: rgba(0, 0, 0, 1);
        font-size: 14px;
        font-weight: 400;
        line-height: 20.27px;
        color: rgba(0, 0, 0, 1)
      }
    }
  }
  .contact_way2{
    position: fixed;
    top: 50%;
    left: calc(50% + 800px);
    transform: translateY(-50%);
    z-index: 1000;
    // width: 153px;
    // height: 426px;
    width: 60px;
    padding: 0 6px;
    border-radius: 30px;
    // background: rgba(255, 255, 255, 1);
    // box-shadow: 0px 0px 8px  rgba(0, 0, 0, 0.15);
    .contactItem{
      width: 100%;
      // padding: 18px 0px;
      // width: 60px;
      // height: 60px;
      width: 48px;
      height: 48px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom: 0.5px solid rgba(166, 166, 166, 1);
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 6px  rgba(0, 0, 0, 0.15);
      // border-radius: 10px;
      border-radius: 50%;
      margin-bottom: 14px;
      // padding-top: 5px;
      &:last-child{
        border-bottom: none;
      }
      &>img{
        width: 25px;
        height: 25px;
      }
      &>.txt{
        margin-top: 3px;
        // font-size: 12px;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 22px;
        color: rgba(56, 56, 56, 1);
      }
      &.wx>img{
        width: 29px;
        height: 23px;
      }
      &.pho>img{
        margin-left: 2px;
      }
      &.top{
        margin-bottom: 0;
        &>img{
          margin-left: 1px;
        }
      }
    }
  }
}
.wechat_popover{
  display: flex;
  flex-direction: column;
  align-items: center;
  &>img{
    margin: 12px 0;
    width: 94px;
    height: 94px;
  }
}
.phone_popover{
  // padding: 17px 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 5px;
  &>img{
    width: 36px;
    height: 36px;
  }
  .right{
    margin-left: 12px;
    height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .phone{
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0px;
      // line-height: 22px;
      color: rgba(56, 56, 56, 1);
    }
    .time{
      // margin-top: 1px;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      // line-height: 22px;
      color: rgba(128, 128, 128, 1);
    }
  }
}
</style>
