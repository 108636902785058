<template>
    <div class="liveStyle" v-if="showLive">
        <div class="liveCard" v-if="isShow" :style="liveCardStyle">
            <div class="playArea">
                <div class="mp4" v-if="liveObj.videoUrl||liveObj.playBackUrl" @click="clickMp4">
                    <video ref="videoDom" muted :src="liveObj.videoUrl||liveObj.playBackUrl" @canplay="canplay" @ended="ended" @loadeddata="loadeddata"></video>
                </div>
            </div>
            <div class="watchNum" v-show="false">
                <div class="effect"></div>
                <div class="num">2154观看</div>
            </div>
            <div class="brandLogo">
                <!-- <img src="../assets/imgs/effect23.png"/> -->
                <img :src="liveObj.imgUrl"/>
            </div>
            <div class="liveName">{{liveObj.title}}</div>
            <div class="desc">
                {{liveObj.remark}}
            </div>


        </div>
        <el-dialog
            title=""
            :visible.sync="dialogVisible"
            width="900px"
            custom-class="liveDia"
            @close="closeDia1">
            <!-- <div class="box">
                <video ref="diaVideoDom" :src="diaVideoUrl" @canplay="diaCanplay" @ended="diaEnded"></video>
            </div> -->
            
            <div class="box">
                <video class="bg" :src="diaVideoUrl"></video>
                <div id="player"></div>
            </div>
        </el-dialog>
    </div>
    
</template>

<script>
import {getLiveRoomList} from "@/axios/api"
let player=null
export default {
    data(){
        return{
            showLive:false,
            isShow:false,
            liveObj:{},
            dialogVisible:false,
            diaVideoUrl:"",
            liveCardStyle:{
                top:""
            },
            liveList:[],
            currentIndex:0,
        }
    },
    methods: {
        handleClose(){},
        gainLiveRoomList(){
            getLiveRoomList().then(res=>{
                console.log("直播间列表",res);
                if(res.code==200&&res.data.length>0){
                    this.isShow=true;
                    this.liveObj=res.data[0];
                    this.liveList=res.data;
                    this.currentIndex=0;

                    this.$nextTick(()=>{
                        this.scrollOpe()
                    })
                }else{
                    this.isShow=false;
                }
            })
        },
        scrollOpe(){
            var liveCard=document.querySelector(".liveCard");
            if(!liveCard){
                return
            }
            var style=getComputedStyle(liveCard);
            var top=parseFloat(style.top);
            var shakeTime=null;
            document.querySelector("#app").addEventListener("scroll",(e)=>{
                // console.log(12345)

                if(shakeTime){
                    clearTimeout(shakeTime)
                }
                shakeTime=setTimeout(()=>{
                    
                    // // console.log(top)
                    // // console.log(e.target.scrollTop)
                    // if(e.target.scrollTop>=550){
                    //     liveCard.style.top=e.target.scrollTop+50+'px';
                    // }else{
                    //     liveCard.style.top=top+'px';
                    // }
                    // // console.log(liveCard.style)

                    this.liveCardStyle.top=e.target.scrollTop+window.innerHeight/2+'px';
                },500)
            })
        },
        setVideoEvent(){},
        // video事件
        canplay(){
            this.$refs.videoDom.play();
        },
        ended(){
            this.$refs.videoDom.play();
        },
        clickMp4(){
            this.diaVideoUrl=this.liveObj.videoUrl||this.liveObj.playBackUrl;
            this.dialogVisible=true;
            this.$nextTick(()=>{
                if(!player){
                    this.initPlayer()
                }else{
                    player.play();
                }
            })
        },

        diaCanplay(){
            this.$refs.diaVideoDom.play();
        },
        diaEnded(){
            this.$refs.diaVideoDom.play();
        },
        closeDia1(){
            // console.log(this.$refs)
            // this.$refs.diaVideoDom.pause();
            player.pause();
        },

        async loadeddata(){
            // var video=this.$refs.videoDom;

            // // 创建canvas元素
            // const canvas = document.createElement('canvas');
            // const ctx = canvas.getContext('2d');
            
            // // 设置canvas的宽和高与视频的宽和高一致
            // canvas.width = video.videoWidth;
            // canvas.height = video.videoHeight;
            
            // // 将视频的当前帧绘制到canvas上
            // ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            
            // // 获取canvas上的图像数据
            // const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            
            // // 可以通过imageData来进行图像处理或显示
            // console.log("11111",imageData);
            // console.log(canvas.toDataURL())
            // document.body.appendChild(canvas)

            // var video = this.$refs.videoDom;
            // video.currentTime = 1; // 视频的时间戳，单位为秒
            // video.addEventListener('seeked', function() {
            //     var canvas = document.createElement('canvas');
            //     canvas.width = video.videoWidth;
            //     canvas.height = video.videoHeight;
            //     var context = canvas.getContext('2d');
            //     context.drawImage(video, 0, 0, canvas.width, canvas.height);
            //     document.body.appendChild(canvas)
            //     // var imageData = canvas.toDataURL(); // 获取base64编码的图片数据
            //     const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            //     console.log("222",imageData)
            // });



            // var video = this.$refs.videoDom;
            // console.log(this.$refs)
            // // 获取Video Track
            // const videoTrack = video.videoTracks[0];
            
            // // 创建ImageCapture对象
            // const imageCapture = new ImageCapture(videoTrack);
            
            // // 获取第一帧图像
            // const firstFrame = await imageCapture.grabFrame();
            
            // // 将图像数据转换为canvas元素
            // const canvas = document.createElement('canvas');
            // const ctx = canvas.getContext('2d');
            // canvas.width = firstFrame.width;
            // canvas.height = firstFrame.height;
            // ctx.drawImage(firstFrame, 0, 0, canvas.width, canvas.height);
            
            // // 获取canvas上的图像数据
            // const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            
            // // 可以通过imageData来进行图像处理或显示
            // console.log(imageData);


            // var video = this.$refs.videoDom;
            // video.currentTime=1
            // html2canvas(video, {
            //     useCORS: true, // 允许跨域请求
            //     onrendered: function(canvas) {
            //         var imageData = canvas.toDataURL(); // 获取base64编码的图片数据
            //         // 处理图片数据

            //         console.log("444",imageData)
            //     }
            // }).then(res=>{
            //     console.log("then",res)
            //     document.body.appendChild(res)
            // });
        },
        // 播放器初始化
        initPlayer(){
            player=new Player({
                id: 'player',
                autoplay: true,
                loop:this.liveList.length>1?false:true,
                volume: 0.3,
                url:this.diaVideoUrl,
                poster: "//lf9-cdn-tos.bytecdntp.com/cdn/expire-1-M/byted-player-videos/1.0.0/poster.jpg",
                playsinline: true,
                height: "100%",
                width: "100%"
            });

            if(this.liveList.length>1){
                player.on('ended',()=>{
                    this.xgPlayerEnd();
                })
            }
        },
        // 西瓜视频监听播放结束
        xgPlayerEnd(){
            if(this.currentIndex<this.liveList.length-1){
                this.currentIndex++;
            }else if(this.currentIndex>=this.liveList.length-1){
                this.currentIndex=0;
            }
            var url=this.liveList[this.currentIndex].videoUrl||this.liveList[this.currentIndex].playBackUrl;

            // player.playNext({
            //     url: url,
            // })

            player.src = url
        }
    },
    created() {
        this.$bus.$on("limitShowLive",(val)=>{
            if(this.showLive){
                return
            }
            if(val=="Y"){
                this.showLive=true;
                this.liveCardStyle.top=document.querySelector("#app").scrollTop+window.innerHeight/2+'px';
                
                this.gainLiveRoomList();
            }else{
                this.showLive=false;
            }
        })
    },
    mounted() {
        
    },
}
</script>

<style lang="less" scoped>
.liveStyle{
    .liveCard{
        position: absolute;
        // top: 551px;
        // top: 50%;
        left: calc(50% - 950px);
        transform: translateY(-50%);
        z-index: 1000;
        background: #ccc;
        // width: 225px;
        // height: 383px;
        width: 182px;
        height: 289px;
        box-shadow: 0px 2px 8px  rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        transition: all 0.3s;
        .playArea{
            width: 100%;
            // height: 294px;
            height: 217px;
            // background: #000;
            border-radius: 10px 10px 0 0;
            overflow: hidden;
            .mp4{
                width: 100%;
                height: 100%;
                cursor: pointer;
                video{
                    width: 100%;
                    height: 100%;
                    background: #000;
                    object-fit: cover;
                }
            }
        }
        .watchNum{
            position: absolute;
            top: 12px;
            left: 10px;
            width: 107px;
            height: 30px;
            border-radius: 17px;
            background: rgba(0, 0, 0, 0.4);
            display: flex;
            align-items: center;
            .effect{
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: linear-gradient(144.34deg, rgba(255, 107, 142, 1) 0%, rgba(230, 0, 54, 1) 100%);
            }
            .num{
                flex: 1;
                height: 100%;
                padding: 0 6px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                display: flex;
                align-items: center;
            }
        }
        .brandLogo{
            position: absolute;
            // top: 270px;
            top: 197.635px;
            left: 10.5px;
            // width: 48px;
            // height: 48px;
            width: 38.73px;
            height: 38.73px;
            border-radius: 50%;
            &>img{
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        .liveName{
            // margin-top: 29px;
            margin-top: 18px;
            // font-size: 18px;
            font-size: 16px;
            font-weight: 700;
            color: rgba(56, 56, 56, 1);
            padding: 0 10px;
        }
        .desc{
            margin-top: 4px;
            // font-size: 16px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(128, 128, 128, 1);
            padding: 0 10px;
        }
    }  
    // .el-dialog__wrapper{
    //     z-index: 1000;
    // }
    /deep/.liveDia{
        .el-dialog__header{
            padding: 0;
            .el-dialog__headerbtn{
                font-size: 20px;
                font-weight: 700;
                color: #000;
                z-index: 1000;
            }
        }
        .el-dialog__body{
            padding: 0px;
            .box{
                width: 100%;
                height: 650px;
                position: relative;
                video{
                    width: 100%;
                    height: 100%;
                    background: #000;
                }
                video.bg{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: absolute;
                    top: 0;
                    left: 0;
                    filter: blur(21px);
                }
                .xgplayer-skin-default{
                    background: transparent;
                    video{
                        background: transparent;
                    }
                }
            }
            // #player{
            //     width: 100%;
            //     height: 650px;
            // }
        }
    }
}

</style>