<template>
  <div class="headComp" :style="headCompStyle">
	  <div class="topRail">
		  <div class="centerWrapper">
			  <div class="left">
				  <span class="shouye" @click="$router.push('/')">
					  <img src="../assets/imgs/home.png">
					  首页
					</span>
				  <!-- <span v-show="!userInfo.userId">Hi,欢迎来到AI奥格雅商城！请</span> -->
				  <span v-show="!userInfo.userId">Hi,欢迎来到{{websiteName}}！请</span>
				  <span v-show="!userInfo.userId" class="toLogin" @click="toLogin">登录</span>
				  <span v-show="!userInfo.userId" class="freeRegister" @click="toRegister">免费注册</span>

				  <span class="avatar" v-show="userInfo.userId"><img :src="userInfo.avatar"></span>
				  <span class="nick" v-show="userInfo.userId" @click="$router.push('/personal/center')">{{userInfo.handledName}}</span>
				  <span class="arrow" v-show="userInfo.userId">
					  <i v-show="!showOPtion" class="el-icon-arrow-down" @click="showOPtion=!showOPtion"></i>
					  <i v-show="showOPtion" class="el-icon-arrow-up" @click="showOPtion=!showOPtion"></i>
					  <div class="options" v-show="showOPtion">
						  <!-- <div>账号设置</div>
						  <div>我的消息</div> -->
						  <div @click="quit">退出</div>
					  </div>
				  </span>
			  </div>
			  <div class="right">
				  <span>帮助中心</span>
				  <span>服务热线：028-8888-8888</span>
				  <!-- <span>我的收藏</span> -->
				  <span @click="clickToShopping">购物车 (0)</span>
				  <span v-show="userInfo.userId" @click="clickToOrder">我的订单</span>
				  <span v-show="userInfo.userId" @click="$router.push('/personal/center')">个人中心</span>
			  </div>
		  </div>
	  </div>
	  <div class="pageMain" v-show="showSearch">
		  <div class="centerWrapper">
			  <div class="brand">
				  <div class="logo" @click="$router.push('/')">
					  <!-- <img :src="websiteLogo?websiteLogo:require('../assets/imgs/brand.png')"> -->
					  <img v-if="websiteLogo" :src="websiteLogo">
				  </div>
				  <div class="searchbox">
					  <!-- <input placeholder="您想要找什么？" />
					  <i class="el-icon-search"></i> -->
						<el-input placeholder="您想要找什么？" class="inputGroup" v-model="keyword" @keydown.native="keydown" autocomplete="off">
							<el-button slot="append" icon="el-icon-search" class="themeBackColor" @click="clickSearch"></el-button>
						</el-input>
				  </div>
				  <div class="partner">
					  <div @click="$router.push('/league')">
						  <img src="../assets/imgs/invite.png">
						  <img src="../assets/imgs/invite_active.png">
						  <span>招商</span>
					  </div>
					  <div>
						  <img src="../assets/imgs/partner.png">
						  <img src="../assets/imgs/partner_active.png">
						  <span>全国10000+合作伙伴</span>
					  </div>
				  </div>
			  </div>
			  <div class="navBar">
				  <div :class="{'navItem':1,}" :style="{'background':currentNav==i?websiteThemeColor:'','color':currentNav==i?'#fff':''}" v-for="(item,i) in navList" :key="i" @click="clickNav(item,i)" v-show="!item.isShow||item.isShow=='Y'">
					  <!-- <img v-show="currentNav!=i" class="icon" :src="'./imgs/nav-'+item.icon+'1.png'"/>
					  <img v-show="currentNav==i" class="icon" :src="'./imgs/nav-'+item.icon+'2.png'"/> -->
					  <i class="icon" :style="{'color':currentNav==i?'#fff':websiteThemeColor}" :class="item.icon_"></i>
					  {{item.label}}
					  <!-- <div class="hint" v-show="currentNav!=i">
						  <img v-show="item.label=='甄选套餐'" src="../assets/imgs/effect14.png"/>
						  <img v-show="item.label=='高定专区'" src="../assets/imgs/effect15.png"/>
					  </div> -->
				  </div>
			  </div>
		  </div>
	  </div>
  </div>
</template>

<script>
import {getUserInfo,logout,getWebsiteSetting,getWebsiteSettingColumn} from "@/axios/api"
export default {
	name: 'headComp',
	components: {},
	data(){
		return{
			keyword:"",
			showSearch:true,
			defaultActive:"",
			currentNav:0,
			navList:[
				{
					label:"首页",
					icon:"home",
					path:"/",
					isShow:"Y",
					icon_:"iconfont icon-shouye",
					type:1
				},
				{
					label:"甄选套餐",
					icon:"combo",
					path:"/combo",
					isShow:"N",
					icon_:"iconfont icon-zhenxuantaocan",
					type:2
				},
				{
					label:"随心配",
					icon:"wish",
					path:"/wishMatch",
					isShow:"N",
					icon_:"iconfont icon-suixinpei",
					type:3
				},
				{
					label:"高定专区",
					icon:"couture",
					path:"/couture",
					isShow:"N",
					icon_:"iconfont icon-gaodingzhuanqu",
					type:4
				},
				{
					label:"全屋定制",
					icon:"allHouse",
					path:"/houseCombo",
					isShow:"N",
					icon_:"iconfont icon-quanwudingzhi",
					type:5
				},
				{
					label:"智慧家居",
					icon:"jiaju",
					path:"/smartHome",
					isShow:"N",
					icon_:"iconfont icon-zhihuijiaju",
					type:6
				},
				{
					label:"建材馆",
					icon:"material",
					path:"/buildingMaterial",
					isShow:"N",
					icon_:"iconfont icon-jiancaiguan",
					type:7
				},
				{
					label:"生活馆",
					icon:"life",
					path:"/lifeHouse",
					isShow:"N",
					icon_:"iconfont icon-jiancaiguan",
					type:12
				},
				{
					label:"客户案例",
					icon:"case",
					path:"/case",
					isShow:"N",
					icon_:"iconfont icon-kehuanli",
					type:8
				},
				{
					label:"品牌馆",
					icon:"brand",
					path:"/brandfest",
					isShow:"N",
					icon_:"iconfont icon-pinpaiguan",
					type:9
				},
			],
			userInfo:{},
			showOPtion:false,
			websiteLogo:"",
			websiteName:"",
			websiteThemeColor:"",
			headCompStyle:{},
		}
	},
	created(){
		// this.modTop();

		this.gainWebsiteSetting();

		var index=this.navList.findIndex(item=>item.path==this.$route.path);
		console.log(index)
		this.currentNav=index;
		if(this.$route.path.indexOf('/person')!=-1){
			this.showSearch=false;
		}else{
			this.showSearch=true;
		}

		if(localStorage.AOGEYA_loginInfo){
			if((new Date().getTime()-parseInt(localStorage.AOGEYA_expireTime))/1000/86400>2){
				localStorage.removeItem("AOGEYA_loginInfo")
				localStorage.removeItem("AOGEYA_expireTime")
			}else{
				this.gainUserInfo();
				this.gainWebsiteSetting();
			}
		}
		if(this.$route.path.indexOf('/merchandise')!=-1&&sessionStorage.searchKeyword){
			this.keyword=sessionStorage.searchKeyword;
		}

		this.$bus.$on("loginStatus",(val)=>{
			if(val){
				this.gainUserInfo();
				this.gainWebsiteSetting();
			}
		})
	},
	methods: {
		clickNav(item,i){
			if(sessionStorage.AOGEYA_wishClassifyList){
				sessionStorage.removeItem("AOGEYA_wishClassifyList")
			}

			this.currentNav=i;
			if(item.path!=''){
				this.$router.push(item.path)
			}
		},
		toLogin(){
			this.$router.push("/login")
		},
		toRegister(){
			this.$router.push("/register")
		},
		clickSearch(){
			sessionStorage.searchKeyword=this.keyword;
			if(this.$route.path!='/merchandise'){
				sessionStorage.removeItem('brandId');
				sessionStorage.removeItem('home_typeId');
				
				this.$router.push('/merchandise')
			}else{
				this.$emit('keyChange',this.keyword)
			}
		},
		keydown($event){
			if($event.keyCode==13){
				this.clickSearch();
			}
		},
		// 获取用户信息
		gainUserInfo(){
			getUserInfo().then(res=>{
				console.log("用户信息",res);
				if(res.code==200){
					if(res.data.nickName){
						res.data.handledName=res.data.nickName
					}else{
						res.data.handledName=res.data.telephone.substring(0,3)+"****"+res.data.telephone.substring(7)
					}
					this.userInfo=res.data;
					localStorage.AOGEYA_userInfo=JSON.stringify(res.data)
				}else{
					this.userInfo={};
					localStorage.removeItem("AOGEYA_userInfo")
				}
			})
		},
		// 退出
		quit(){
			logout().then(res=>{
				console.log("退出结果",res);
				localStorage.removeItem('AOGEYA_loginInfo');
				this.userInfo={}
				localStorage.removeItem("AOGEYA_userInfo");
				localStorage.removeItem("AOGEYA_businessId");
				localStorage.removeItem("AGY_wholesale_token");
				// this.gainUserInfo();
			})
		},

		// 查询网站配置
		gainWebsiteSetting(){
			getWebsiteSetting({
				host:location.host,
				// host:"www.i-dian.com",
			}).then(res=>{
				console.log("网站配置h",res);
				if(res.data.websiteLogo){
					this.websiteLogo=res.data.websiteLogo;
				}
				if(res.data.businessId){
					localStorage.AOGEYA_businessId=res.data.businessId
				}
				if(res.data.websiteName){
					this.websiteName=res.data.websiteName;
				}
				if(res.data.bannerColour){
					this.websiteThemeColor=res.data.bannerColour;
					var rgb=this.hexToRgb(res.data.bannerColour);
					console.log("rgb",rgb)
					var styleArr=[
						`.themeTextColor{color:${res.data.bannerColour}}`,
						`.themeCircleColor{background:rgba(${rgb.red},${rgb.green},${rgb.blue},0.15)}`,
						`.themeHouseBack{background:rgba(${rgb.red},${rgb.green},${rgb.blue},0.1)}`,
						`.themeBackColor{background:rgba(${rgb.red},${rgb.green},${rgb.blue},1)!important}`,
					]
					var styleStr=styleArr.join('')
					// console.log("styleStr",styleStr)
					var styleEle=document.createElement('style');
					var styleText=document.createTextNode(styleStr);
					styleEle.appendChild(styleText);
					// console.log(styleEle)
					document.body.appendChild(styleEle)
				}
				// res.data.bannerPlaceList.forEach(item=>{
				// 	if(item.bannerPlace==1){
				// 		this.homeBannerList=item.bannerSettingList
				// 	}
				// })

				this.gainColumnSetting(res.data.websiteSettingId)
			})
		},
		modTop() {
			if(['/furnitureDetail'].includes(this.$route.path)){
				this.headCompStyle={
				'position':'relative'
				}
			}else{
				this.headCompStyle={}
			}
		},
		clickToShopping(){
			if(this.userInfo.userId){
				this.$router.push('/myShoppingCart')
			}else{
				this.$commonFun.confirmBox(this,"未登录，是否前往登录？",'/login')
			}
		},
		clickToOrder(){
			if(this.userInfo.userId){
				this.$router.push("/personal/myOrder")
			}else{
				this.$commonFun.confirmBox(this,"未登录，是否前往登录？",'/login')
			}
		},
		// 获取栏目
		gainColumnSetting(id){
			getWebsiteSettingColumn({websiteSettingId:id}).then(res=>{
				console.log("栏目集合",res);
				if(res.code==200){
					this.navList.forEach((item,k)=>{
						// for(var i=0;i<res.data.length;i++){
						// 	if(res.data[i].name==item.label){
						// 		item.isShow=res.data[i].isShow
						// 		// if(i==8){
						// 		// 	item.isShow="N"
						// 		// }
						// 		break
						// 	}
						// }

						for(var i=0;i<res.data.length;i++){
							if(res.data[i].type==item.type){
								item.isShow=res.data[i].isShow
								item.label=res.data[i].name
								break
							}
						}
					})
					var index1=res.data.findIndex(it=>it.type==10);
					this.$bus.$emit("limitShowLive",res.data[index1].isShow);

					var index2=res.data.findIndex(it=>it.type==11);
					this.$bus.$emit("limitShowRight",res.data[index2].isShow);
					
					var index3=res.data.findIndex(it=>it.type==2);
					this.$bus.$emit("limitShowPrefer",res.data[index3].isShow);
					console.log("navList",this.navList)
				}
			})
		},
		// 16进制转rgb
		hexToRgb(hex) {
			// 移除前缀#符号
			hex = hex.replace(/^\s*#|\s*$/g, '');
			
			// 将三位十六进制数转换为十进制数值
			let red = parseInt(hex.substr(0, 2), 16);
			let green = parseInt(hex.substr(2, 2), 16);
			let blue = parseInt(hex.substr(4, 2), 16);
			
			return { red, green, blue };
		}
	},
	watch:{
		'$route'(n,o){
			// this.modTop();
		}
	}
}
</script>

<style lang="less" scoped>
.headComp{
	width: 100%;
	// position: fixed;
	position: relative;
	top: 0;
	left: 0;
	z-index: 1001;
	background: #fff;
	.topRail{
		// height: 60px;
		// height: 50px;
		height: 40px;
		background: rgba(240, 240, 240, 1);
		font-size: 14px;
		.centerWrapper{
			// height: 60px;
			// height: 50px;
			height: 40px;
			display: flex;
			justify-content: space-between;
			.left{
				height: 100%;
				display: flex;
				align-items: center;
				.shouye{
					margin-right: 10px;
					display: flex;
					align-items: center;
					cursor: pointer;
					img{
						width: 13px;
						margin-right: 10px;
					}
					&:hover{
						color: rgba(37, 126, 93, 1);
					}
				}
				.toLogin{
					cursor: pointer;
					&:hover{
						color: rgba(37, 126, 93, 1);
					}
				}
				.freeRegister{
					margin-left: 10px;
					border-left: 2px solid #aaa;
					padding-left: 10px;
					cursor: pointer;
					&:hover{
						color: rgba(37, 126, 93, 1);
					}
				}

				.avatar{
					margin-left: 60px;
					img{
						width: 24px;
					}
				}
				.nick{
					margin: 0 15px;
					font-size: 16px;
					font-weight: 400;
					color: #333;
				}
				.arrow{
					position: relative;
					&>i{
						cursor: pointer;
						font-size: 16px;
						font-weight: 400;
						color: #333;

					}
					.options{
						position: absolute;
						right: 0;
						top:40px;
						width: 100px;
						&>div{
							height: 24px;
							background: rgba(229, 229, 229, 1);
							font-size: 12px;
							font-weight: 400;
							color: #333;
							margin-top: 3px;
							display: flex;
							align-items: center;
							justify-content: center;
							cursor: pointer;
						}
					}
				}
			}
			.right{
				height: 100%;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				&>span{
					margin-left: 10px;
					border-left: 2px solid #aaa;
					padding-left: 10px;
					cursor: pointer;
					&:nth-child(1){
						margin-left: 0;
						border-left: none;
						padding-left: 0;
					}
					&:hover{
						color: rgba(37, 126, 93, 1);
					}
				}
			}
		}
	}
	.pageMain{
		.brand{
			// padding: 15px 0;
			// padding-top: 15px;
			padding-top: 5px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.logo{
				width: 200px;
				cursor: pointer;
				img{
					width: 100%;
					height: 85px;
					object-fit: contain;
				}
			}
			.searchbox{
				width: 827px;
				// height: 60px;
				height: 48px;
				// border-radius: 4px;
				border-radius: 24px;
				background: rgba(240, 240, 240, 1);
				.inputGroup{
					height: 100%;
					/deep/ .el-input__inner{
						background: transparent;
						border: none;
						height: 100%;
					}
					/deep/ .el-input-group__append{
						background: transparent;
						border: none;
						button{
							border-radius: 0 24px 24px 0;
							color: #fff;
							width: 77px;
						}
						.el-icon-search{
							font-size: 24px;
						}
					}
				}
			}
			.partner{
				height: 60px;
				display: flex;
				align-items: center;
				&>div{
					display: flex;
					flex-direction: column;
					align-items: center;
					font-size: 16px;
					font-weight: 400;
					color: #000;
					cursor: pointer;
					img{
						margin-bottom: 9px;
						width: 35px;
						height: 31px;
						&:nth-child(2){
							display: none;
						}
					}
					&:nth-child(2){
						margin-left: 35px;
					}
					&:hover{
						color: rgba(255, 141, 26, 1);
						&>img:nth-child(1){
							display: none;
						}
						&>img:nth-child(2){
							display: inline;
						}
					}
				}
			}
		}
		.navBar{
			// height: 70px;
			height: 50px;
			display: flex;
			align-items: center;
			.navItem{
				// height: 70px;
				// height: 44px;
				height: 36px;
				padding: 0 17px;
				background: transparent;
				margin-right: 17px;
				display: flex;
				align-items: center;
				font-size: 16px;
				font-weight: 400;
				color: #333;
				position: relative;
				cursor: pointer;
				border-radius: 4px;
				&:hover{
					color: rgba(37, 126, 93, 1);
					.icon{
						animation: rotatoIcon 0.5s
					}
				}
				&:last-child{
					margin-right: 0;
				}
				.icon{
					margin-right: 5px;
					width: 25px;
				}
				&.active{
					background: rgba(37, 126, 93, 1);
					color: #fff;
				}
				.hint{
					position: absolute;
					left: 50%;
					bottom: 60%;
					transform: translateX(-50%)
				}
			}
		}
	}
	@keyframes rotatoIcon {
		0%{
			transform: rotate(0)
		}
		100%{
			transform: rotate(360deg)
		}
	}
}
</style>
